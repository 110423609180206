import React, { useState, useEffect, useCallback } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { ROUTE } from "@/constants";
import { ScrollView } from "devextreme-react";
import { ChevronDownIcon, ChevronUpIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { PageProps } from "@/pages/_app";
import { userAtom } from "@/store/global";
import { useAtom } from "jotai";
import { checkCondition, checkVersion, QA_FLAG_DEV, QA_FLAG_STAGING } from "@vhows/util";
import { menuV1, menuV2, menuV3 } from "@constants/sidebar-data";
import { menuV4 } from "@constants/sidebar-data-v4";

const Sidebar = ({ sidebarUse, sidebarOpen, ability }: PageProps) => {
  const [collapseMenu, setCollapseMenu] = useState(""); // 아코디언 펼칠 메뉴
  const [selectedMenu, setSelectedMenu] = useState(""); // 실제 라우트경로와 일치하는 선택한 메뉴
  const [companyName, setCompanyName] = useState<string>("그린랩스");
  const [user] = useAtom(userAtom);
  const router = useRouter();

  const menuGroup = checkVersion("0.0.8", QA_FLAG_STAGING)
    ? menuV3
    : checkVersion("0.0.6", QA_FLAG_STAGING)
    ? menuV2
    : menuV1;

  const bindCompanyName = useCallback(async () => {
    if (user) {
      const companyName = user.companyName as string;
      setCompanyName(companyName);
    }
  }, [user]);

  useEffect(() => {
    // 라우트 그룹에 벗어나는 메뉴를 위한 초기화
    setSelectedMenu("");
    setCollapseMenu("");
    menuGroup.map((group: any, groupIndex: number) => {
      group.route.map((menu: any, index: number) => {
        const menuID = groupIndex.toString() + index.toString();
        if (menu.children.length > 0) {
          menu.children.map((subMenu: any) => {
            // if (router.pathname.indexOf(subMenu.link) > -1) {
            if (router.pathname === subMenu.link) {
              setSelectedMenu(menuID);
              setCollapseMenu(menuID);
            }
          });
        } else {
          if (router.pathname === menu.link) {
            setSelectedMenu(menuID);
            setCollapseMenu(menuID);
          }
        }
      });
    });
    bindCompanyName();
  }, [bindCompanyName, router]);

  const onClickAccordion = (value: string) => {
    if (collapseMenu === value) {
      setCollapseMenu("");
    } else {
      setCollapseMenu(value);
    }
  };

  const titleVisibleCheck = (group: any) => {
    let visible = false;

    group.route.forEach((menu: any) => {
      if (ability.can(menu.action ? menu.action : "read", menu.resource)) {
        return (visible = true);
      }
    });
    return visible;
  };

  return (
    <div className={`fixed z-40 h-screen ${sidebarUse ? (sidebarOpen ? "" : "hidden") : "hidden"}`}>
      <div className="h-full w-60 border-r bg-white" id="sidenavSecExample">
        <div className="flex px-6 pt-4 pb-2">
          <Link href={"/"}>
            <a>
              <div className="flex items-center">
                <div className="shrink-0">
                  <Image src={"/logo.png"} width={36} height={36} />
                </div>
              </div>
            </a>
          </Link>
          <Link href={ROUTE.DASHBOARD}>
            <a className="self-center">
              <div className="flex items-center">
                <div className="ml-3 grow">
                  <p className="text-primary text-xl font-semibold">{companyName}</p>
                </div>
              </div>
            </a>
          </Link>
        </div>
        <ScrollView className={"side-bar"}>
          <div>
            {menuGroup.map((group: any, groupIndex: number) => {
              return (
                <div key={groupIndex}>
                  {/*<hr className="my-2" />*/}
                  {titleVisibleCheck(group) && (
                    <div
                      className={`px-6 text-xs text-gray-600 ${group.admin ? "bg-gray-150 pt-6 pb-2" : "mt-6 mb-2"}`}
                    >
                      {group.title}
                    </div>
                  )}
                  <ul className={`relative ${group.admin ? "" : "px-1"}`}>
                    {group.route.map((menu: any, index: number) => {
                      const menuID = groupIndex.toString() + index.toString();
                      if (ability.can(menu.action ? menu.action : "read", menu.resource)) {
                        return (
                          <li className={`relative  ${group.admin ? "bg-gray-150 pb-2 px-1" : "mb-2"}`} key={index}>
                            <Link href={menu.link} passHref={menu.passHref}>
                              {menu.children.length > 0 ? (
                                <>
                                  <a
                                    className={`flex h-10 cursor-pointer items-center overflow-hidden text-ellipsis whitespace-nowrap py-4 px-6 text-sm text-gray-700 transition duration-300 ease-in-out ${
                                      collapseMenu === menuID
                                        ? "bg-green-50 hover:bg-green-100 rounded"
                                        : group.admin
                                        ? "bg-gray-150 hover:bg-green-50 hover:rounded"
                                        : "bg-white hover:bg-green-50 rounded"
                                    }`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#target" + menuID}
                                    aria-expanded="false"
                                    aria-controls={"target" + menuID}
                                    onClick={() => {
                                      onClickAccordion(menuID);
                                    }}
                                    target={menu.target}
                                  >
                                    {menu.icon}
                                    <span className="pl-2 text-base" data-cy="menu-label">
                                      {menu.label}
                                    </span>
                                    {collapseMenu === menuID ? (
                                      <ChevronUpIcon className={"ml-auto h-5 w-5"} />
                                    ) : (
                                      <ChevronDownIcon className={"ml-auto h-5 w-5"} />
                                    )}
                                  </a>
                                  <ul
                                    className={`accordion-collapse relative ${
                                      collapseMenu === menuID ? "!visible" : "collapse"
                                    }`}
                                    id={"target" + menuID}
                                    aria-labelledby="sidenavSecEx2"
                                    data-bs-parent="#sidenavSecExample"
                                  >
                                    {menu.children.map((subMenu: any, index: number) => {
                                      return (
                                        ability.can(
                                          subMenu.action ? subMenu.action : "read",
                                          subMenu.resource !== undefined ? subMenu.resource : menu.resource,
                                        ) && (
                                          <li className="relative" key={index}>
                                            <Link href={subMenu.link}>
                                              <a
                                                className={`flex h-6 items-center overflow-hidden text-ellipsis whitespace-nowrap py-5 pr-6 pl-10 text-sm transition duration-300 ease-in-out ${
                                                  // router.pathname.indexOf(subMenu.link) > -1
                                                  router.pathname === subMenu.link
                                                    ? "bg-primary text-white hover:bg-green-500 rounded"
                                                    : group.admin
                                                    ? "bg-gray-150 hover:bg-green-50 hover:rounded"
                                                    : "text-gray-700 hover:bg-green-50 rounded"
                                                } ${
                                                  // subMenu.hidden && router.pathname.indexOf(subMenu.link) === -1
                                                  //   ? "hidden"
                                                  //   : ""
                                                  subMenu.hidden ? "hidden" : ""
                                                }`}
                                                target={subMenu.target}
                                              >
                                                {subMenu.icon}
                                                <span className="pl-2" data-cy="subMenu-label">
                                                  {subMenu.label}
                                                </span>
                                              </a>
                                            </Link>
                                          </li>
                                        )
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <a
                                  className={`flex h-10 cursor-pointer items-center overflow-hidden text-ellipsis whitespace-nowrap rounded py-4 px-6 text-sm transition duration-300 ease-in-out ${
                                    router.pathname === menu.link
                                      ? "bg-primary text-white hover:bg-green-500"
                                      : "text-gray-700 hover:bg-green-50"
                                  }`}
                                  target={menu.target}
                                >
                                  {menu.icon}
                                  <span className="pl-2 text-base">{menu.label}</span>
                                </a>
                              )}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              );
            })}
            <div className="h-40 w-full bg-white text-center">
              {/*<hr className="m-0" />*/}
              <div className="mb-6 h-10 text-ellipsis whitespace-nowrap py-4 px-6 text-left text-sm">
                <Link href="/">
                  <a
                    aria-current="page"
                    className="router-link-exact-active router-link-active relative mt-8 mb-2"
                    target="_self"
                  >
                    <Image src="/logo.png" alt="vhows" height={"30px"} width={"30px"} />
                    <span className="text-primary absolute mt-1 ml-3 overflow-hidden text-ellipsis whitespace-nowrap text-xl">
                      브이하우스
                    </span>
                  </a>
                </Link>
                {/* <div className="mt-1">
                  <PhoneIcon className={"inline-block h-5 w-5 text-gray-700"} />
                  <span className="ml-2 text-sm text-gray-700">02-6953-4379</span>
                </div>
                <span className="ml-7 text-xs text-gray-700">월~금요일 10:00~18:00</span> */}
              </div>
            </div>
          </div>
        </ScrollView>
      </div>
    </div>
  );
};

export default Sidebar;
