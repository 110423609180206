import { AbilityBuilder, Ability } from "@casl/ability";
import { forEach } from "lodash-es";
import { AUTH, ROLE } from "@constants/common";

export default function defineAbilityFor(user: User) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  forEach(user.roles, role => {
    switch (role.id) {
      case ROLE.ROLE_PLATFORM_DEVELOPER:
      case ROLE.ROLE_PLATFORM_ADMIN:
        can("manage", AUTH.ALL);
        break;
      case ROLE.ROLE_CUSTOMER:
        cannot("manage", AUTH.ALL);
        can("manage", [AUTH.AUTH, AUTH.USER]);
        break;
      case ROLE.ROLE_ENTERPRISE_MANAGER:
        can("manage", [
          AUTH.AUTH,
          AUTH.USER,
          AUTH.DASHBOARD,
          AUTH.CUSTOMER,
          AUTH.DESIGN,
          AUTH.ESTIMATE,
          AUTH.DESIGN_PREFERENCE,
          AUTH.PESTIMATE,
          AUTH.COMPANY,
          AUTH.ITEM,
          AUTH.EMPLOYEE,
          AUTH.COUNSEL,
        ]);
        can("read", [AUTH.CHARGE]);
        cannot("pudge", AUTH.DESIGN);
        break;
      case ROLE.ROLE_PLATFORM_USER:
        can("manage", [AUTH.AUTH, AUTH.USER, AUTH.DASHBOARD, AUTH.CUSTOMER, AUTH.COUNSEL]);
        can(["create", "read", "update", "delete"], [AUTH.DESIGN, AUTH.ESTIMATE]);
        can("read", [AUTH.COMPANY, AUTH.ITEM, AUTH.CHARGE, AUTH.DESIGN_PREFERENCE, AUTH.EMPLOYEE]);
        break;
      case ROLE.ROLE_ENTERPRISE_USER:
        can("manage", [AUTH.AUTH, AUTH.USER, AUTH.DASHBOARD, AUTH.CUSTOMER, AUTH.COUNSEL]);
        can(["create", "read", "update", "delete"], [AUTH.DESIGN, AUTH.ESTIMATE]);
        can("read", [AUTH.COMPANY, AUTH.ITEM, AUTH.CHARGE, AUTH.EMPLOYEE, AUTH.DESIGN_PREFERENCE]);
        break;
      default:
        cannot("manage", AUTH.ALL);
    }
  });
  return rules;
}
