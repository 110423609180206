import React, { useEffect } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useRouter } from "next/router";
import "font/pretendard/pretendard-subset.css";
import "@greenlabs/formula-components/formula.css";
import { ToastContainer } from "react-toastify";
import type { AppProps } from "next/app";
import "react-toastify/dist/ReactToastify.css";
import { useAtomsDebugValue } from "jotai-devtools";
import Layouts from "@components/layouts";
import "../styles/globals.css";
import "devextreme/dist/css/dx.light.css";
import "../styles/devextreme-custom.css";
import { loadMessages, locale } from "devextreme/localization";
import dxKoText from "@/i18n/devextreme/ko.json";
import { ability, AbilityContext } from "@feature/auth";
import { setRouter } from "@/store/global";
import { LoadPanel } from "devextreme-react/load-panel";
import { useAtom } from "jotai";
import { loadPanelAtom } from "@/store/global";
import { GoogleAnalytics, QA_FLAG_DEV } from "@vhows/util";
import Script from "next/script";

if (typeof window !== "undefined") {
  // @ts-ignore - @types/tw-elements가 현재 없습니다.
  import("tw-elements");
}

export type PageProps = any;

const queryClient = new QueryClient({
  defaultOptions: {
    // 의도치 않은 fetch 발생으로 인하여 서버 요청 부하를 줄이기 위한 설정
    queries: {
      staleTime: 100,
      refetchOnWindowFocus: false,
    },
  },
});

// @ts-ignore
function DebugAtoms() {
  useAtomsDebugValue();
  return null;
}

loadMessages(dxKoText);
locale("ko");

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [loadPanel] = useAtom(loadPanelAtom);
  setRouter(router);

  const messageEventHandler = (e: MessageEvent<any>) => {
    if (e.origin === process.env.NEXT_PUBLIC_MEMBER_URL) {
      // @ts-ignore
      e.source.postMessage("domain", e.origin);
    } else if (e.origin === process.env.NEXT_PUBLIC_VUE_BASE_URL) {
      e.data === "getDesign" ? location.reload() : null;
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageEventHandler, false);
    return () => {
      window.removeEventListener("message", messageEventHandler, false);
    };
  }, []);

  return (
    <>
      {process.env.NEXT_PUBLIC_GA_ID && GoogleAnalytics(process.env.NEXT_PUBLIC_GA_ID)}

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AbilityContext.Provider value={ability}>
            <DebugAtoms />
            <Layouts>
              <Component className="dx-viewport" {...pageProps} />
            </Layouts>
          </AbilityContext.Provider>
          <ToastContainer autoClose={2000} theme="colored" />
          <LoadPanel
            closeOnOutsideClick={false}
            message={"잠시만 기다려주세요."}
            position={{ my: "center", at: "center" }}
            shading={true}
            showIndicator={true}
            showPane={true}
            visible={loadPanel}
            shadingColor={"rgba(0,0,0,0.4)"}
          />
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
