export const ROUTE = {
  HOME: "/",
  SIGNUP: "/signup",
  ADMIN: "/admin",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  NOTAUTHORIZED: "/not-authorized",
  OAUTH: "/oauth/redirect",

  // 병해충 처방
  // 결과.로그 데이터
  CROP_DIAGNOSIS_RESULT: "/crop/diagnosisresult",
  CROP_SATISFACTION: "/crop/satisfaction",
  CROP_PRODUCT_CONNECTIONS: "/crop/product/connections",
  CROP_PESTICIDE_COMPANY_CONNECTIONS: "/crop/pesticide/company/connections",
  CROP_PRODUCT_SELECTIONS: "/crop/product/selections",

  // 기능.컨텐츠 데이터
  CROP_DISEASE_LIST: "/crop/diseaselist",
  CROP_DISEASE_INFO_NCPMS: "/crop/diseaseinfoncpms",
  CROP_PRODUCT_INFO: "/crop/product/list",
  CROP_PESTICIDE_INFO: "/crop/pesticideinfo",
  CROP_MONTHLY_INFO: "/crop/monthlyinfo",

  // 고객 관리
  // 내 조직
  CUSTOMER_LIST: "/customer/list",
  CUSTOMER_CREATE: "/customer/create",
  CUSTOMER_UPDATE: "/customer/update",
  CUSTOMER_UPDATE_ID: "/customer/update/[id]",

  // 간편견적
  HCOST: "https://howscost.farmmorning.com/",
  HCOST_LIST: "/inbound/hcost/list",
  HCOST_RESULT_CREATE: "/hcost/result/create",
  HCOST_MODEL_LIST: "/hcost/model/list",
  HCOST_MODEL_UPDATE: "/hcost/model/update/[modelKey]",
  HCOST_FACTOR_LIST: "/hcost/factor/list",

  // 수익성분석
  PROFIT: "https://profit.farmmorning.com/",
  PROFIT_CREATE: "/profit/create",
  PROFIT_LIST: "/inbound/profit/list",
  PROFIT_MODEL_LIST: "/profit/model/list",
  PROFIT_MODEL_UPDATE: "/profit/model/update/[id]",
  PROFIT_FACTOR_LIST: "/profit/factor/list",
  PROFIT_MODEL_CREATE: "/profit/model/create",

  // 병해충 관리
  DISEASE_LIST: "/disease/monthly/list",

  // 설계 관리
  DESIGN_LIST: "/design/list",
  DESIGN_CREATE: "/design/create",
  DESIGN_MODIFY: "/design/detail/[id]",
  DESIGN_UPDATE: "/design/detail",
  DESIGN_LIST_DELETED: "/design/list/deleted",
  DESIGN_PREFERENCE: "/design/preference",
  DESIGN_ESTIMATE: "/design/:id/estimate/:estimateId",
  DESIGN_ESTIMATE_REVIEW: "/design/:id/pestimate/:estimateId",
  DESIGN_BLUEPRINT: "/design/blueprint/:id",
  DESIGN_SPECIFICATION: "/design/spec/:id",
  DESIGN_MATERIAL: "/design/material/:id",
  DESIGN_DETAILED: "/design/detailed/:id",

  // 설계 관리 - admin
  DEFAULT_DESIGN_PREFERENCE: "/admin/default/design/preference",
  DEFAULT_DESIGN_TEMPLATE_CREATE: "/admin/default/design/template/create",
  DEFAULT_DESIGN_TEMPLATE_UPDATE: "/admin/default/design/template/update",
  DEFAULT_DESIGN_TEMPLATE_LIST_DELETE: "/admin/default/design/template/list/deleted",
  DEFAULT_DESIGN_TEMPLATE: "/admin/default/design/template/:modifyType/:id",

  // 사용자/조직관리
  PROFILE: "/user/profile",
  USER_PROFILE_PASSWORD: "/user/profile/password",
  COMPANY_PROFILE: "/company/profile",
  CHARGE_PREFERENCE: "/charge/preference",
  EMPLOYEE_LIST: "/employee/list",
  EMPLOYEE_UPDATE: "/employee/update",
  EMPLOYEE_UPDATE_USERNAME: "/employee/update/[username]",
  EMPLOYEE_CREATE: "/employee/create",
  COMPANY_EMPLOYEE_UPDATE: "company/employee/update",
  COMPANY_EMPLOYEE_CREATE: "company/employee/create",
  COMPANY_EMPLOYEE_LIST: "company/employee/list",

  // 품목 관리
  ITEM_LIST: "/item/product/list",
  ITEM_UPDATE: "/item/product/update",
  ITEM_UPDATE_ID: "/item/product/update/[id]",
  ITEM_CATEGORY_CREATE_ADMIN: "/admin/default/item/category/create",
  ITEM_CATEGORY_LIST_ADMIN: "/admin/default/item/category/list",
  ITEM_CREATE_ADMIN: "/admin/default/item/product/create",
  ITEM_LIST_ADMIN: "/admin/default/item/product/list",

  // 조직관리
  ADMIN_COMPANY: "/admin/company",
  ADMIN_COMPANY_CREATE: "/admin/company/create",
  ADMIN_COMPANY_UPDATE: "/admin/company/update",
  ADMIN_COMPANY_UPDATE_ID: "/admin/company/update/[id]",
  ADMIN_COMPANY_LIST: "/admin/company/list",
  ADMIN_COMPANY_CHARGE: "/admin/company/[companyId]/charge",
  ADMIN_COMPANY_DESIGN_CREATE: "/admin/company/[companyId]/design/create",
  ADMIN_COMPANY_DESIGN_LIST: "/admin/company/[companyId]/design/list",
  ADMIN_COMPANY_DESIGN_LIST_DELETED: "/admin/company/[companyId]/design/list/deleted",
  ADMIN_COMPANY_DESIGN_DETAIL: "/admin/company/[companyId]/design/detail/[id]",
  ADMIN_COMPANY_DESIGN_PREFERENCE: "/admin/company/[companyId]/design/preference",
  ADMIN_COMPANY_DESIGN_TEMPLATE_CREATE: "/admin/company/[companyId]/design/template/create",
  ADMIN_COMPANY_DESIGN_TEMPLATE_UPDATE: "/admin/company/[companyId]/design/template/update",
  ADMIN_COMPANY_DESIGN_TEMPLATE_LIST_DELETED: "/admin/company/[companyId]/template/list/deleted",
  ADMIN_COMPANY_ITEM_LIST: "/admin/company/[companyId]/item/product/list",
  ADMIN_COMPANY_ITEM_UPDATE: "/admin/company/[companyId]/item/product/update/[id]",
  ADMIN_COMPANY_CUSTOMER_CREATE: "/admin/company/[companyId]/customer/create",
  ADMIN_COMPANY_CUSTOMER_LIST: "/admin/company/[companyId]/customer/list",
  ADMIN_COMPANY_CUSTOMER_UPDATE: "/admin/company/[companyId]/customer/update/[id]",
  ADMIN_COMPANY_EMPLOYEE_CREATE: "/admin/company/[companyId]/employee/create",
  ADMIN_COMPANY_EMPLOYEE_LIST: "/admin/company/[companyId]/employee/list",
  ADMIN_COMPANY_EMPLOYEE_UPDATE: "/admin/company/[companyId]/employee/update/[username]",

  // (구)상담 관리
  CONTRACT_CREATE: "/contract/create",
  CONTRACT_LIST: "/contract/list",
  CONTRACT_LIST_DELETED: "/contract/list/deleted",

  // 상담 관리
  COUNSEL_CREATE: "/counsel/create",
  COUNSEL_LIST: "/counsel/list",
  COUNSEL_LIST_DELETED: "/counsel/list/deleted",
  COUNSEL_ADMIN_LIST: "/admin/counsel/list",
  COUNSEL_MODEL_LIST: "/admin/counsel/model/list",
  COUNSEL_MODEL_UPDATE: "/admin/counsel/model/update/[modelKey]",
  COUNSEL_FACTOR_LIST: "/admin/counsel/factor/list",

  // 중개 관리
  BROKERAGE_LIST: "/brokerage/list",
  BROKERAGE_ADMIN_LIST: "/admin/brokerage/list",

  // 사용자/역할 관리
  BUSINESS_USER_LIST: "/admin/user/business/list",
  BUSINESS_USER_CREATE: "/admin/user/business/create",
  BUSINESS_USER_UPDATE: "/admin/user/business/update/[username]",
  CUSTOMER_USER_LIST: "/admin/user/customer/list",
  CUSTOMER_USER_CREATE: "/admin/user/customer/create",
  CUSTOMER_USER_UPDATE: "/admin/user/customer/update/[username]",
  ADMIN_ROLE_LIST: "/admin/user/role/list",
  ADMIN_AUTHORITY_LIST: "/admin/user/authority/list",

  // 정책 및 약관
  POLICY_TERMS: "/policy/terms",
  POLICY_PRIVACY: "/policy/privacy",
  POLICY_LEGAL: "/policy/legal",

  // 요금제
  CHARGE_PLAN: "/charge/plan",
};
