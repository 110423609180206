import { LoadPanel } from "devextreme-react/load-panel";
import { useState, useEffect } from "react";

export type Props = ReturnType<any>;

const Loadpanel = ({ loading }: Props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    onOff(loading);
  }, [loading]);

  const onOff = (loading: boolean) => {
    setVisible(loading);
  };

  const off = () => {
    setVisible(false);
  };

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ my: "center", at: "center", of: window }}
        onHiding={off}
        visible={visible}
        message={"잠시만 기다려주세요."}
        showIndicator={true}
        shading={false}
        showPane={true}
        hideOnOutsideClick={false}
      />
    </>
  );
};

export default Loadpanel;
