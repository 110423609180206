import React, { memo } from "react";
import Link from "next/link";
import Image from "next/image";
import { useQueryClient } from "react-query";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { ROUTE } from "@/constants";
import Button from "@components/common/button";
import { userAtom } from "@/store/global";
import { useAtomValue } from "jotai";
import { TextHeadline } from "@greenlabs/formula-components";

type Navbar = {
  navbarUse: boolean;
  sidebarUse: boolean;
  sidebarOpen: boolean;
  callbacks: Function;
};

const Navbar = ({ navbarUse, sidebarUse, sidebarOpen, callbacks }: Navbar) => {
  const navbarDefaultClass = `fixed bg-white z-30 flex flex-wrap justify-between items-center py-4 text-gray-700 border-b border-gray-100 navbar navbar-expand-lg navbar-light ${
    sidebarUse && sidebarOpen ? "ml-60 w-[calc(100vw_-_15rem)]" : "w-full"
  }`;
  let userName: string = "";
  let userImage: string = "";
  const user = useAtomValue(userAtom);
  if (user) {
    const { name, profileImage } = user;
    userName = name;
    userImage = profileImage;
    callbacks("sidebarUse");
  }
  return (
    <nav className={`${navbarDefaultClass}${navbarUse ? "" : " hidden"}`}>
      <div
        className={`container-fluid flex w-full flex-wrap items-center justify-between ${
          sidebarUse ? "px-6" : "pl-0 pr-1"
        } sm:px-6`}
      >
        <div className="flex justify-center">
          <div className={`flex content-center items-center ${sidebarUse ? "hidden" : ""}`}>
            {/*브이하우스 로고*/}
            <div className="flex pl-6 pr-3 justify-center content-center">
              <Link href={"/"} passHref={true}>
                <a className="flex items-center">
                  <Image src={"/logo.png"} width={27} height={30} alt={"로고"} />
                </a>
              </Link>
              <Link href={ROUTE.DASHBOARD} passHref={true}>
                <a className="flex justify-center">
                  <TextHeadline className="ml-1.5" color="primary-contents" size="lg" weight="bold">
                    브이하우스
                  </TextHeadline>
                </a>
              </Link>
            </div>
          </div>
          <div className={`${sidebarUse ? "" : "hidden"}`}>
            <div className="form-check form-switch mb-7">
              <input
                className="form-check-input float-left -ml-10 h-5 w-9 cursor-pointer appearance-none rounded-full bg-gray-300 bg-contain bg-no-repeat align-top shadow-sm focus:outline-none"
                type="checkbox"
                role="switch"
                checked={sidebarOpen}
                onChange={(event: any) => {
                  callbacks("sidebar");
                }}
              />
            </div>
          </div>
        </div>
        <div className="relative flex items-center">
          <span className={`mr-4 text-sm ${sidebarUse && sidebarOpen ? "hidden sm:block" : "block"}`}>
            {userName && userName + " 님"}
          </span>
          {userName ? (
            <div className="dropdown relative">
              <a
                className="hidden-arrow dropdown-toggle flex items-center"
                href="#"
                id="dropdownMenuButton2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {userImage ? (
                  <Image className="h-8 w-8 rounded-full" src={userImage} width="32" height="32" alt="user-image" />
                ) : (
                  <UserCircleIcon className="h-8 w-8 text-primary" />
                )}
              </a>
              <ul
                className="dropdown-menu absolute right-0 left-auto z-50 float-left m-0 mt-1 hidden min-w-max list-none rounded-lg border-none bg-white bg-clip-padding py-2 text-left text-base shadow-lg"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <button
                    className="dropdown-item block w-full whitespace-nowrap bg-transparent py-2 px-4 text-left text-sm font-normal text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      callbacks("profile", "dropdown");
                    }}
                  >
                    프로필
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item block w-full whitespace-nowrap bg-transparent py-2 px-4 text-left text-sm font-normal text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      callbacks("charge", "dropdown");
                    }}
                  >
                    요금제
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item block w-full whitespace-nowrap bg-transparent py-2 px-4 text-left text-sm font-normal text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      callbacks("logout", "dropdown");
                    }}
                  >
                    로그아웃
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <Button
                color="green"
                className="mr-4"
                onClick={() => {
                  callbacks("signup", "dropdown");
                }}
              >
                업체 가입
              </Button>
              <Button
                color="green"
                className=""
                onClick={() => {
                  callbacks("login", "dropdown");
                }}
              >
                로그인
              </Button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
