import { createContext, useEffect } from "react";
import { NextComponentType } from "next";
import { useRouter } from "next/router";
import { useAtom } from "jotai";
import { createContextualCan } from "@casl/react";
import { Ability } from "@casl/ability";
import { setUserAtom, userAtom } from "@/store/global";
import { CLIENT, ROUTE } from "@/constants";
import { PageProps } from "@/pages/_app";
import defineAbilityFor from "@utils/permissions";
import jwtService from "@service/jwt-service";

const ABILITY_KEY = "ability";

export const AbilityContext = createContext(undefined);
export const Can = createContextualCan(AbilityContext.Consumer);

export const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },
];

export const setAbility = (ability: any) => {
  localStorage.setItem(ABILITY_KEY, JSON.stringify(ability));
};

export const getAbility = () => {
  if (localStorage.getItem(ABILITY_KEY)) {
    return JSON.parse(localStorage.getItem(ABILITY_KEY));
  } else {
    return initialAbility;
  }
};

export const ability = new Ability(initialAbility);

export const moveToLoginPage = (router: any) => {
  // if (process.env.NEXT_PUBLIC_DEV_ENV === "local") {
  //   router.replace(ROUTE.LOGIN);
  // } else {
  //   window.location.assign(process.env.NEXT_PUBLIC_BASE_PATH + "/login");
  // }
  router.replace(ROUTE.LOGIN);
};

export const setUserData = async () => {
  const { data } = await jwtService.getUserData();
  ability.update(defineAbilityFor(data));
  // setAbility(ability);
  return data;
};

export function withAuth(WrappedComponent: NextComponentType) {
  return function Auth(props: PageProps) {
    const router = useRouter();
    const [user, setUser] = useAtom(userAtom);
    const accessiblePath = [ROUTE.HOME, ROUTE.LOGIN, ROUTE.OAUTH];

    useEffect(() => {
      const auth = jwtService.getToken() !== undefined && jwtService.getToken() !== null;

      // 대시보드 & 정책 및 약관 페이지 일때
      if (
        router &&
        (router.asPath == "/" ||
          router.pathname === ROUTE.POLICY_TERMS ||
          router.pathname === ROUTE.POLICY_PRIVACY ||
          router.pathname === ROUTE.POLICY_LEGAL)
      ) {
        if (!auth) {
        } else {
          if (auth && !user) {
            setUserData().then(res => {
              // @ts-ignore
              setUser(res);
            });
          }
        }
      } // 로그인 페이지가 아니면서 토큰 없을때
      else if (router && router.asPath !== "/login" && router.asPath.indexOf("redirect") < 0 && !auth) {
        // clearClientData(queryClient);
        localStorage.removeItem(CLIENT.USER_DATA);
        localStorage.setItem(CLIENT.REDIRECT, router.pathname);
        localStorage.removeItem(ABILITY_KEY);
        moveToLoginPage(router);
      } else {
        // 토큰이 있고 유저 데이터가 없을때
        if (auth && !user) {
          setUserData().then(res => {
            // @ts-ignore
            setUser(res);

            if (props.resource && !ability.can(props.action ? props.action : "read", props.resource)) {
              router.push(ROUTE.NOTAUTHORIZED);
            }
          });
        } // 그 외 토큰이 있고 로그인 페이지가 아니면서 유저 데이터가 있을때
        // else if (router && router.asPath !== "/login" && auth && getClientData(CLIENT.USER_DATA, queryClient)) {
        //   const data = getClientData(CLIENT.USER_DATA, queryClient);
        //   ability.update(defineAbilityFor(data));
        // } else {
        //   // 로그인 페이지
        // }
      }
      setUserAtom(setUser);
    }, [router, setUser]);

    if (router && accessiblePath.indexOf(router.pathname) !== -1) {
      return <WrappedComponent {...props} />;
    }

    return user && <WrappedComponent {...props} />;
  };
}
