import React, { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import Sidebar from "@components/layouts/sidebar";
import Navbar from "@components/layouts/navbar";
import { useAbility } from "@casl/react";
import { useQueryClient } from "react-query";
import { useAtom } from "jotai";
import { AbilityContext, moveToLoginPage, initialAbility } from "@feature/auth";
import jwtService from "@service/jwt-service";
import { ROUTE } from "@/constants";
import { userAtom } from "@/store/global";
import LoadPanel from "@components/common/load-panel";
import Footer from "@components/layouts/footer";

type Layouts = {
  children?: React.ReactNode;
};

const Layouts = ({ children }: Layouts): React.ReactElement => {
  const router = useRouter();
  const [sidebarUse, setSidebarUse] = useState(false);
  const [navbarUse, setNavbarUse] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const queryClient = useQueryClient();
  const ability = useAbility(AbilityContext);

  const handleResize = () => {
    setSidebarOpen(!(window.innerWidth < 1200));
    setSidebarUse(true);
  };

  const layoutStateCheck = useCallback(async () => {
    if (router.pathname === "/signup" || router.pathname === "/login" || router.pathname === "/not-authorized") {
      setSidebarUse(false);
      setNavbarUse(false);
    } else if (
      router.pathname === "/" ||
      router.pathname === ROUTE.POLICY_TERMS ||
      router.pathname === ROUTE.POLICY_PRIVACY ||
      router.pathname === ROUTE.POLICY_LEGAL
    ) {
      // const ClientData: any = await getClientData(CLIENT.USER_DATA, queryClient);
      if (user) {
        // if (jwtService.getToken()) {
        handleResize();
      } else {
        setSidebarUse(false);
      }
      setNavbarUse(true);
    } else {
      setSidebarUse(true);
      setNavbarUse(true);
    }
  }, [router.pathname, user]);

  useEffect(() => {
    layoutStateCheck();
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };

    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", end);
    router.events.on("routeChangeError", end);

    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", end);
      router.events.off("routeChangeError", end);
    };
  }, [layoutStateCheck, router]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  const logout = () => {
    jwtService.removeToken();
    jwtService.removeRefreshToken();
    sessionStorage.clear();
    // @ts-ignore
    setUser(null);
    ability.update(initialAbility);
    moveToLoginPage(router);
  };

  const navbarClick = (value: string, type: string) => {
    if (type === "dropdown") {
      switch (value) {
        case "profile":
          router.push(ROUTE.PROFILE);
          break;
        case "charge":
          router.push(ROUTE.CHARGE_PREFERENCE);
          break;
        case "logout":
          logout();
          break;
        case "login":
          router.push(ROUTE.LOGIN);
          break;
        case "signup":
          window.open(`${process.env.NEXT_PUBLIC_MEMBER_URL}/partner`);
          break;
        default:
          break;
      }
    } else {
      if (value === "sidebar") {
        setSidebarOpen(!sidebarOpen);
      } else if (value === "sidebarUse") {
        // setSidebarUse(true);
      }
    }
  };

  return (
    <div className="relative min-h-[100vh]">
      <Sidebar sidebarUse={sidebarUse} sidebarOpen={sidebarOpen} ability={ability} />
      <Navbar navbarUse={navbarUse} sidebarUse={sidebarUse} sidebarOpen={sidebarOpen} callbacks={navbarClick} />
      {loading ? (
        <LoadPanel loading={loading} />
      ) : (
        <>
          <div
            className={`fixed z-10 block h-screen w-full bg-gray-800/50 ${
              sidebarUse && sidebarOpen ? "sm:hidden sm:bg-transparent" : "hidden bg-transparent"
            }`}
          />
          <div
            className={`${router.pathname === "/" && "pb-64"} ${sidebarUse ? (sidebarOpen ? "sm:ml-60 " : "") : ""}${
              navbarUse ? " pt-16" : ""
            }`}
          >
            {children}
          </div>
        </>
      )}
      {!!router && router.pathname === "/" && <Footer sidebarUse={sidebarUse} sidebarOpen={sidebarOpen} />}
    </div>
  );
};

export default Layouts;
