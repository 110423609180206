import { atom, PrimitiveAtom } from "jotai";
import { NextRouter } from "next/router";

let _router: NextRouter;

let _setUser: any;

export function setRouter(router: NextRouter) {
  _router = router;
}

export function getRouter() {
  return _router;
}

export function setUserAtom(setUser: any) {
  _setUser = setUser;
}

export function getUserAtom() {
  return _setUser;
}

export const userAtom = atom<User | null>(null) as PrimitiveAtom<User | null>;
export const loadPanelAtom = atom(false);

export const districtsAtom = atom<DistrictsResponse[]>([]);
