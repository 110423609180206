import notify from "devextreme/ui/notify";
import { ItemRenderedEvent } from "devextreme/ui/tree_view";

export function makeRowSpan(rowData: any[]) {
  const { data } = rowData.reduce(
    (acc, v, i) => {
      if (acc.groupId !== v.groupId) {
        if (acc.rowId) {
          const findData = acc.data.find((v: any) => v.id == acc.rowId);

          acc.data = acc.data.map((v: any) => (v.id === acc.rowId ? { ...findData, rowspan: acc.rowspan } : { ...v }));
        }
        acc.rowspan = 1;
        acc.rowId = v.id;
        acc.groupId = v.groupId;
        acc.data.push({ ...v, rowspan: acc.rowspan });
      } else {
        acc.data.push({ ...v, rowspan: 0 });
        acc.rowspan++;
        if (i === rowData.length - 1 && acc.rowId) {
          const findData = acc.data.find((v: any) => v.id == acc.rowId);

          acc.data = acc.data.map((v: any) => (v.id === acc.rowId ? { ...findData, rowspan: acc.rowspan } : { ...v }));
        }
      }
      return acc;
    },
    { data: [], rowspan: 0, rowId: 0, groupId: 0 },
  );

  return data;
}

type Position =
  | "top left"
  | "top center"
  | "top right"
  | "left center"
  | "center"
  | "right center"
  | "bottom left"
  | "bottom center"
  | "bottom right";

type NotifyType = "info" | "warning" | "error" | "success";
export function commonNotify(
  message: string,
  type: NotifyType = "success",
  at: Position = "top center",
  x: number = 0,
  y: number = 44,
  my: Position = "top center",
) {
  notify({ message: message, type: type, position: { at: at, offset: { x: x, y: y }, my: my } });
}

export function createDataTree(dataset: any[]) {
  const hashTable = Object.create(null);
  dataset.forEach(aData => (hashTable[aData.itemNumber] = { ...aData, selected: false, childNodes: [] }));
  const dataTree: any[] = [];
  dataset.forEach(aData => {
    if (aData.parentItemNumber) hashTable[aData.parentItemNumber].childNodes.push(hashTable[aData.itemNumber]);
    else dataTree.push(hashTable[aData.itemNumber]);
  });
  return dataTree;
}

export function treeViewRootCheckBoxHidden(item: ItemRenderedEvent) {
  if (!item.node.parent) {
    item.itemElement.parentElement.firstElementChild.classList.add("!hidden");
    item.itemElement.parentElement.children[1].classList.add("!pl-2.5");
  }
}
