import { ROUTE } from "@constants/route";
import { AUTH } from "@constants/common";

export const menuV1: Array<MenuGroup> = [
  {
    title: "",
    route: [
      {
        label: "대시보드",
        link: ROUTE.DASHBOARD,
        icon: null,
        resource: AUTH.DASHBOARD,
        children: [],
      },
      {
        label: "사용자/조직 관리",
        link: "",
        icon: null,
        resource: AUTH.DASHBOARD,
        children: [
          {
            label: "내 프로필",
            icon: <>∙</>,
            link: ROUTE.PROFILE,
            resource: AUTH.USER,
          },
          {
            label: "조직 프로필",
            icon: <>∙</>,
            link: ROUTE.COMPANY_PROFILE,
            resource: AUTH.USER,
          },
          {
            label: "조직 요금제",
            icon: <>∙</>,
            link: ROUTE.CHARGE_PREFERENCE,
            resource: AUTH.COMPANY,
          },
          {
            label: "조직원 관리",
            icon: <>∙</>,
            link: ROUTE.EMPLOYEE_LIST,
            resource: AUTH.EMPLOYEE,
          },
        ],
      },
    ],
  },
  {
    title: "전문설계 서비스",
    route: [
      {
        label: "설계 관리",
        link: "",
        icon: null,
        resource: AUTH.DESIGN,
        children: [
          {
            label: "설계 등록",
            icon: <>∙</>,
            link: ROUTE.DESIGN_CREATE,
            resource: AUTH.DESIGN,
            action: "create",
          },
          {
            label: "설계 목록",
            icon: <>∙</>,
            link: ROUTE.DESIGN_LIST,
            resource: AUTH.DESIGN,
          },
          {
            label: "설계 설정",
            icon: <>∙</>,
            link: ROUTE.DESIGN_PREFERENCE,
            resource: AUTH.DESIGN_PREFERENCE,
          },
          {
            label: "설계 휴지통",
            icon: <>∙</>,
            link: ROUTE.DESIGN_LIST_DELETED,
            resource: AUTH.DESIGN,
            action: "manage",
          },
        ],
      },
      {
        label: "품목 관리",
        link: "",
        icon: null,
        resource: AUTH.ITEM,
        children: [
          {
            label: "상품 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_LIST,
            resource: AUTH.ITEM,
          },
        ],
      },
      {
        label: "고객 관리",
        link: "",
        icon: null,
        resource: AUTH.CUSTOMER,
        children: [
          {
            label: "고객 등록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_CREATE,
            resource: AUTH.CUSTOMER,
          },
          {
            label: "고객 목록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_LIST,
            resource: AUTH.CUSTOMER,
          },
        ],
      },
    ],
  },
  {
    title: "기본 서비스 (운영자)",
    route: [
      {
        label: "조직 관리",
        link: "",
        icon: null,
        resource: AUTH.ADMIN_COMPANY,
        children: [
          {
            label: "조직 등록",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_CREATE,
            resource: AUTH.ADMIN_COMPANY,
          },
          {
            label: "조직 목록",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_LIST,
            resource: AUTH.ADMIN_COMPANY,
          },
        ],
      },
      {
        label: "사용자/역할 관리",
        link: "",
        icon: null,
        resource: AUTH.ADMIN_USER,
        children: [
          {
            label: "사용자 등록",
            icon: <>∙</>,
            link: ROUTE.BUSINESS_USER_CREATE,
            resource: AUTH.ADMIN_USER,
            action: "create",
          },
          {
            label: "사용자 목록",
            icon: <>∙</>,
            link: ROUTE.BUSINESS_USER_LIST,
            resource: AUTH.ADMIN_USER,
          },
          {
            label: "고객 사용자 등록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_USER_CREATE,
            resource: AUTH.ADMIN_USER,
            action: "create",
          },
          {
            label: "고객 사용자 목록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_USER_LIST,
            resource: AUTH.ADMIN_USER,
          },
          {
            label: "사용자 역할",
            icon: <>∙</>,
            link: ROUTE.ADMIN_ROLE_LIST,
            resource: "owner",
          },
          {
            label: "사용자 권한",
            icon: <>∙</>,
            link: ROUTE.ADMIN_AUTHORITY_LIST,
            resource: "owner",
          },
        ],
      },
    ],
  },
  {
    title: "자재 서비스 (운영자)",
    route: [
      {
        label: "인바운드 DB관리",
        link: "",
        icon: null,
        resource: AUTH.PROFIT,
        children: [
          {
            label: "간편견적 인바운드 DB",
            icon: <>∙</>,
            link: ROUTE.HCOST_LIST,
            resource: AUTH.HCOST,
          },
          {
            label: "수익성 인바운드 DB",
            icon: <>∙</>,
            link: ROUTE.PROFIT_LIST,
            resource: AUTH.PROFIT,
          },
        ],
      },
      {
        label: "간편견적 관리",
        link: "",
        icon: null,
        resource: AUTH.HCOST,
        children: [
          {
            label: "간편견적 등록 (고객용)",
            icon: <>∙</>,
            link: process.env.NEXT_PUBLIC_INBOUND_URL,
            passHref: true,
            target: "_blank",
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "간편견적 등록 (관리자)",
            icon: <>∙</>,
            link: ROUTE.HCOST_RESULT_CREATE,
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "간편견적 모델 관리",
            icon: <>∙</>,
            link: ROUTE.HCOST_MODEL_LIST,
            resource: AUTH.HCOST_MODEL,
          },
          {
            label: "간편견적 요소 관리",
            icon: <>∙</>,
            link: ROUTE.HCOST_FACTOR_LIST,
            resource: AUTH.HCOST_FACTOR,
          },
        ],
      },
      {
        label: "수익성 관리",
        link: "",
        icon: null,
        resource: AUTH.PROFIT,
        children: [
          {
            label: "수익성 등록 (고객용)",
            icon: <>∙</>,
            link: ROUTE.PROFIT,
            passHref: true,
            target: "_blank",
            resource: AUTH.PROFIT,
            action: "create",
          },
          {
            label: "수익성 등록 (관리자)",
            icon: <>∙</>,
            link: ROUTE.PROFIT_CREATE,
            resource: AUTH.PROFIT,
            action: "create",
          },
          {
            label: "수익성 모델 관리",
            icon: <>∙</>,
            link: ROUTE.PROFIT_MODEL_LIST,
            resource: AUTH.PROFIT_MODEL,
          },
          {
            label: "수익성 요소 관리",
            icon: <>∙</>,
            link: ROUTE.PROFIT_FACTOR_LIST,
            resource: AUTH.PROFIT_FACTOR,
          },
        ],
      },
    ],
  },
  {
    title: "전문설계 서비스 (운영자)",
    route: [
      {
        label: "계약 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "계약 등록",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_CREATE,
          },
          {
            label: "계약 목록",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_LIST,
          },
          {
            label: "계약 휴지통",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_LIST_DELETED,
          },
        ],
      },
      {
        label: "설계 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "기본 설계 설정",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_PREFERENCE,
          },
          {
            label: "기본 템플릿 등록",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_CREATE,
          },
          {
            label: "기본 템플릿 수정",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_UPDATE,
          },
          {
            label: "기본 템플릿 휴지통",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_LIST_DELETE,
          },
        ],
      },
      {
        label: "품목 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "기본 분류 등록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CATEGORY_CREATE_ADMIN,
          },
          {
            label: "기본 분류 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CATEGORY_LIST_ADMIN,
          },
          {
            label: "기본 상품 등록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CREATE_ADMIN,
          },
          {
            label: "기본 상품 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_LIST_ADMIN,
          },
        ],
      },
    ],
  },
  {
    title: "기타",
    route: [
      {
        label: "브이하우스 블로그",
        icon: null,
        link: "http://blog.vhows.com/",
        resource: AUTH.AUTH,
        target: "_blank",
        children: [],
      },
      {
        label: "피드백 및 문의",
        icon: null,
        link: "https://docs.google.com/spreadsheets/d/1xZ9bnVKwyyZFNc4MLGbri5cPk_QihF4tE-I8HUJhCLU/edit?usp=sharing",
        resource: AUTH.AUTH,
        target: "_blank",
        children: [],
      },
    ],
  },
];

export const menuV2: Array<MenuGroup> = [
  {
    title: "",
    route: [
      {
        label: "대시보드",
        link: ROUTE.DASHBOARD,
        icon: null,
        resource: AUTH.DASHBOARD,
        children: [],
      },
      {
        label: "사용자/조직 관리",
        link: "",
        icon: null,
        resource: AUTH.DASHBOARD,
        children: [
          {
            label: "내 프로필",
            icon: <>∙</>,
            link: ROUTE.PROFILE,
            resource: AUTH.USER,
          },
          {
            label: "조직 프로필",
            icon: <>∙</>,
            link: ROUTE.COMPANY_PROFILE,
            resource: AUTH.USER,
          },
          {
            label: "조직 요금제",
            icon: <>∙</>,
            link: ROUTE.CHARGE_PREFERENCE,
            resource: AUTH.COMPANY,
          },
          {
            label: "조직원 관리",
            icon: <>∙</>,
            link: ROUTE.EMPLOYEE_LIST,
            resource: AUTH.EMPLOYEE,
          },
        ],
      },
    ],
  },
  {
    title: "전문설계 서비스",
    route: [
      {
        label: "설계 관리",
        link: "",
        icon: null,
        resource: AUTH.DESIGN,
        children: [
          {
            label: "설계 등록",
            icon: <>∙</>,
            link: ROUTE.DESIGN_CREATE,
            resource: AUTH.DESIGN,
            action: "create",
          },
          {
            label: "설계 목록",
            icon: <>∙</>,
            link: ROUTE.DESIGN_LIST,
            resource: AUTH.DESIGN,
          },
          {
            label: "설계 설정",
            icon: <>∙</>,
            link: ROUTE.DESIGN_PREFERENCE,
            resource: AUTH.DESIGN_PREFERENCE,
          },
          {
            label: "설계 휴지통",
            icon: <>∙</>,
            link: ROUTE.DESIGN_LIST_DELETED,
            resource: AUTH.DESIGN,
            action: "manage",
          },
        ],
      },
      {
        label: "품목 관리",
        link: "",
        icon: null,
        resource: AUTH.ITEM,
        children: [
          {
            label: "상품 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_LIST,
            resource: AUTH.ITEM,
          },
        ],
      },
      {
        label: "고객 관리",
        link: "",
        icon: null,
        resource: AUTH.CUSTOMER,
        children: [
          {
            label: "고객 등록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_CREATE,
            resource: AUTH.CUSTOMER,
          },
          {
            label: "고객 목록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_LIST,
            resource: AUTH.CUSTOMER,
          },
        ],
      },
    ],
  },
  {
    title: "기본 서비스 (운영자)",
    route: [
      {
        label: "조직 관리",
        link: "",
        icon: null,
        resource: AUTH.ADMIN_COMPANY,
        children: [
          {
            label: "조직 등록",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_CREATE,
            resource: AUTH.ADMIN_COMPANY,
          },
          {
            label: "조직 목록",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_LIST,
            resource: AUTH.ADMIN_COMPANY,
          },
        ],
      },
      {
        label: "사용자/역할 관리",
        link: "",
        icon: null,
        resource: AUTH.ADMIN_USER,
        children: [
          {
            label: "사용자 등록",
            icon: <>∙</>,
            link: ROUTE.BUSINESS_USER_CREATE,
            resource: AUTH.ADMIN_USER,
            action: "create",
          },
          {
            label: "사용자 목록",
            icon: <>∙</>,
            link: ROUTE.BUSINESS_USER_LIST,
            resource: AUTH.ADMIN_USER,
          },
          {
            label: "고객 사용자 등록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_USER_CREATE,
            resource: AUTH.ADMIN_USER,
            action: "create",
          },
          {
            label: "고객 사용자 목록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_USER_LIST,
            resource: AUTH.ADMIN_USER,
          },
          {
            label: "사용자 역할",
            icon: <>∙</>,
            link: ROUTE.ADMIN_ROLE_LIST,
            resource: "owner",
          },
          {
            label: "사용자 권한",
            icon: <>∙</>,
            link: ROUTE.ADMIN_AUTHORITY_LIST,
            resource: "owner",
          },
        ],
      },
    ],
  },
  {
    title: "자재 서비스 (운영자)",
    route: [
      {
        label: "인바운드 DB관리",
        link: "",
        icon: null,
        resource: AUTH.PROFIT,
        children: [
          {
            label: "간편견적 인바운드 DB",
            icon: <>∙</>,
            link: ROUTE.HCOST_LIST,
            resource: AUTH.HCOST,
          },
          {
            label: "수익성 인바운드 DB",
            icon: <>∙</>,
            link: ROUTE.PROFIT_LIST,
            resource: AUTH.PROFIT,
          },
        ],
      },
      {
        label: "간편견적 관리",
        link: "",
        icon: null,
        resource: AUTH.HCOST,
        children: [
          {
            label: "간편견적 등록 (고객용)",
            icon: <>∙</>,
            link: process.env.NEXT_PUBLIC_INBOUND_URL,
            passHref: true,
            target: "_blank",
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "간편견적 등록 (관리자)",
            icon: <>∙</>,
            link: ROUTE.HCOST_RESULT_CREATE,
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "간편견적 모델 관리",
            icon: <>∙</>,
            link: ROUTE.HCOST_MODEL_LIST,
            resource: AUTH.HCOST_MODEL,
          },
          {
            label: "간편견적 요소 관리",
            icon: <>∙</>,
            link: ROUTE.HCOST_FACTOR_LIST,
            resource: AUTH.HCOST_FACTOR,
          },
        ],
      },
      {
        label: "수익성 관리",
        link: "",
        icon: null,
        resource: AUTH.PROFIT,
        children: [
          {
            label: "수익성 등록 (고객용)",
            icon: <>∙</>,
            link: ROUTE.PROFIT,
            passHref: true,
            target: "_blank",
            resource: AUTH.PROFIT,
            action: "create",
          },
          {
            label: "수익성 등록 (관리자)",
            icon: <>∙</>,
            link: ROUTE.PROFIT_CREATE,
            resource: AUTH.PROFIT,
            action: "create",
          },
          {
            label: "수익성 모델 관리",
            icon: <>∙</>,
            link: ROUTE.PROFIT_MODEL_LIST,
            resource: AUTH.PROFIT_MODEL,
          },
          {
            label: "수익성 요소 관리",
            icon: <>∙</>,
            link: ROUTE.PROFIT_FACTOR_LIST,
            resource: AUTH.PROFIT_FACTOR,
          },
        ],
      },
      {
        label: "병해충 관리",
        link: "",
        icon: null,
        resource: AUTH.DISEASE,
        children: [
          {
            label: "월별 주의 병해충 관리",
            icon: <>∙</>,
            link: ROUTE.DISEASE_LIST,
            resource: AUTH.DISEASE,
          },
        ],
      },
    ],
  },
  {
    title: "전문설계 서비스 (운영자)",
    route: [
      {
        label: "계약 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "계약 등록",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_CREATE,
          },
          {
            label: "계약 목록",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_LIST,
          },
          {
            label: "계약 휴지통",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_LIST_DELETED,
          },
        ],
      },
      {
        label: "설계 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "기본 설계 설정",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_PREFERENCE,
          },
          {
            label: "기본 템플릿 등록",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_CREATE,
          },
          {
            label: "기본 템플릿 수정",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_UPDATE,
          },
          {
            label: "기본 템플릿 휴지통",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_LIST_DELETE,
          },
        ],
      },
      {
        label: "품목 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "기본 분류 등록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CATEGORY_CREATE_ADMIN,
          },
          {
            label: "기본 분류 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CATEGORY_LIST_ADMIN,
          },
          {
            label: "기본 상품 등록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CREATE_ADMIN,
          },
          {
            label: "기본 상품 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_LIST_ADMIN,
          },
        ],
      },
    ],
  },
  {
    title: "기타",
    route: [
      {
        label: "브이하우스 블로그",
        icon: null,
        link: "http://blog.vhows.com/",
        resource: AUTH.AUTH,
        target: "_blank",
        children: [],
      },
      {
        label: "피드백 및 문의",
        icon: null,
        link: "https://docs.google.com/spreadsheets/d/1xZ9bnVKwyyZFNc4MLGbri5cPk_QihF4tE-I8HUJhCLU/edit?usp=sharing",
        resource: AUTH.AUTH,
        target: "_blank",
        children: [],
      },
    ],
  },
];

export const menuV3: Array<MenuGroup> = [
  {
    title: "",
    route: [
      {
        label: "대시보드",
        link: ROUTE.DASHBOARD,
        icon: null,
        resource: AUTH.DASHBOARD,
        children: [],
      },
      {
        label: "사용자/조직 관리",
        link: "",
        icon: null,
        resource: AUTH.DASHBOARD,
        children: [
          {
            label: "내 프로필",
            icon: <>∙</>,
            link: ROUTE.PROFILE,
            resource: AUTH.USER,
          },
          {
            label: "조직 프로필",
            icon: <>∙</>,
            link: ROUTE.COMPANY_PROFILE,
            resource: AUTH.USER,
          },
          {
            label: "조직 요금제",
            icon: <>∙</>,
            link: ROUTE.CHARGE_PREFERENCE,
            resource: AUTH.COMPANY,
          },
          {
            label: "조직원 관리",
            icon: <>∙</>,
            link: ROUTE.EMPLOYEE_LIST,
            resource: AUTH.EMPLOYEE,
          },
          {
            label: "조직원 등록",
            link: ROUTE.EMPLOYEE_CREATE,
            resource: AUTH.EMPLOYEE,
            hidden: true,
          },
          {
            label: "조직원 수정",
            link: ROUTE.EMPLOYEE_UPDATE_USERNAME,
            resource: AUTH.EMPLOYEE,
            hidden: true,
          },
          {
            label: "조직원 비밀번호 수정",
            link: ROUTE.EMPLOYEE_UPDATE_USERNAME + "/password",
            resource: AUTH.EMPLOYEE,
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: "설계/견적 서비스",
    route: [
      {
        label: "상담 관리",
        link: "",
        icon: null,
        resource: AUTH.COUNSEL,
        children: [
          {
            label: "중개 목록",
            icon: <>∙</>,
            link: ROUTE.BROKERAGE_LIST,
          },
          {
            label: "상담 목록",
            icon: <>∙</>,
            link: ROUTE.COUNSEL_LIST,
          },
        ],
      },
      {
        label: "설계 관리",
        link: "",
        icon: null,
        resource: AUTH.DESIGN,
        children: [
          {
            label: "설계 등록",
            icon: <>∙</>,
            link: ROUTE.DESIGN_CREATE,
            resource: AUTH.DESIGN,
            action: "create",
          },
          {
            label: "설계 목록",
            icon: <>∙</>,
            link: ROUTE.DESIGN_LIST,
            resource: AUTH.DESIGN,
          },
          {
            label: "설계 상세",
            link: ROUTE.DESIGN_MODIFY,
            resource: AUTH.DESIGN,
            hidden: true,
          },
          {
            label: "설계 설정",
            icon: <>∙</>,
            link: ROUTE.DESIGN_PREFERENCE,
            resource: AUTH.DESIGN_PREFERENCE,
          },
          {
            label: "설계 휴지통",
            icon: <>∙</>,
            link: ROUTE.DESIGN_LIST_DELETED,
            resource: AUTH.DESIGN,
            action: "manage",
          },
        ],
      },
      {
        label: "품목 관리",
        link: "",
        icon: null,
        resource: AUTH.ITEM,
        children: [
          {
            label: "상품 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_LIST,
            resource: AUTH.ITEM,
          },
          {
            label: "상품 수정",
            link: ROUTE.ITEM_UPDATE_ID,
            resource: AUTH.ITEM,
            hidden: true,
          },
        ],
      },
      {
        label: "고객 관리",
        link: "",
        icon: null,
        resource: AUTH.CUSTOMER,
        children: [
          {
            label: "고객 등록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_CREATE,
            resource: AUTH.CUSTOMER,
          },
          {
            label: "고객 목록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_LIST,
            resource: AUTH.CUSTOMER,
          },
          {
            label: "고객 수정",
            link: ROUTE.CUSTOMER_UPDATE_ID,
            resource: AUTH.CUSTOMER,
            hidden: true,
          },
        ],
      },
      {
        label: "사용 가이드",
        link: "",
        icon: null,
        resource: AUTH.AUTH,
        children: [
          {
            label: "블로그 가이드",
            icon: <>∙</>,
            link: "https://blog.naver.com/vhows/223018842767",
            resource: AUTH.AUTH,
            target: "_blank",
          },
          {
            label: "유튜브 가이드(제작중)",
            icon: <>∙</>,
            link: "https://www.youtube.com/watch?v=x4Jla1DofUc&list=PLRecDyF7Bk9kVwiwaA154AC6Xs9m9bkHs",
            resource: AUTH.AUTH,
            target: "_blank",
          },
          {
            label: "피드백 및 문의",
            icon: <>∙</>,
            link: "https://docs.google.com/spreadsheets/d/15K6cz4LiLFYRH7Bd8ze1WJD2b31moTsnyOEFM__1AOQ/edit#gid=516726851",
            resource: AUTH.AUTH,
            target: "_blank",
          },
        ],
      },
    ],
  },
  {
    title: "기본 서비스 (운영자)",
    admin: true,
    route: [
      {
        label: "조직 관리",
        link: "",
        icon: null,
        resource: AUTH.ADMIN_COMPANY,
        children: [
          {
            label: "조직 등록",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_CREATE,
            resource: AUTH.ADMIN_COMPANY,
          },
          {
            label: "조직 목록",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_LIST,
            resource: AUTH.ADMIN_COMPANY,
          },
          {
            label: "조직 수정",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_UPDATE_ID,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 요금 관리",
            icon: <>∙</>,
            link: ROUTE.ADMIN_COMPANY_CHARGE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 등록",
            link: ROUTE.ADMIN_COMPANY_DESIGN_CREATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 목록",
            link: ROUTE.ADMIN_COMPANY_DESIGN_LIST,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 휴지통",
            link: ROUTE.ADMIN_COMPANY_DESIGN_LIST_DELETED,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 상세",
            link: ROUTE.ADMIN_COMPANY_DESIGN_DETAIL,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 설정",
            link: ROUTE.ADMIN_COMPANY_DESIGN_PREFERENCE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 템플릿 등록",
            link: ROUTE.ADMIN_COMPANY_DESIGN_TEMPLATE_CREATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 템플릿 수정",
            link: ROUTE.ADMIN_COMPANY_DESIGN_TEMPLATE_UPDATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직 설계 템플릿 휴지통",
            link: ROUTE.ADMIN_COMPANY_DESIGN_TEMPLATE_LIST_DELETED,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "상품 목록",
            link: ROUTE.ADMIN_COMPANY_ITEM_LIST,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "상품 수정",
            link: ROUTE.ADMIN_COMPANY_ITEM_UPDATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "고객 등록",
            link: ROUTE.ADMIN_COMPANY_CUSTOMER_CREATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "고객 목록",
            link: ROUTE.ADMIN_COMPANY_CUSTOMER_LIST,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "고객 수정",
            link: ROUTE.ADMIN_COMPANY_CUSTOMER_UPDATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직원 등록",
            link: ROUTE.ADMIN_COMPANY_EMPLOYEE_CREATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직원 목록",
            link: ROUTE.ADMIN_COMPANY_EMPLOYEE_LIST,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직원 수정",
            link: ROUTE.ADMIN_COMPANY_EMPLOYEE_UPDATE,
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
          {
            label: "조직원 비밀번호 수정",
            link: ROUTE.ADMIN_COMPANY_EMPLOYEE_UPDATE + "/password",
            resource: AUTH.ADMIN_COMPANY,
            hidden: true,
          },
        ],
      },
      {
        label: "사용자/역할 관리",
        link: "",
        icon: null,
        resource: AUTH.ADMIN_USER,
        children: [
          {
            label: "사용자 등록",
            icon: <>∙</>,
            link: ROUTE.BUSINESS_USER_CREATE,
            resource: AUTH.ADMIN_USER,
            action: "create",
          },
          {
            label: "사용자 목록",
            icon: <>∙</>,
            link: ROUTE.BUSINESS_USER_LIST,
            resource: AUTH.ADMIN_USER,
          },
          {
            label: "사용자 수정",
            link: ROUTE.BUSINESS_USER_UPDATE,
            resource: AUTH.ADMIN_USER,
            hidden: true,
          },
          {
            label: "사용자 비밀번호 수정",
            link: ROUTE.BUSINESS_USER_UPDATE + "/password",
            resource: AUTH.ADMIN_USER,
            hidden: true,
          },
          {
            label: "고객 사용자 등록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_USER_CREATE,
            resource: AUTH.ADMIN_USER,
            action: "create",
          },
          {
            label: "고객 사용자 목록",
            icon: <>∙</>,
            link: ROUTE.CUSTOMER_USER_LIST,
            resource: AUTH.ADMIN_USER,
          },
          {
            label: "고객 사용자 수정",
            link: ROUTE.CUSTOMER_USER_UPDATE,
            resource: AUTH.ADMIN_USER,
            hidden: true,
          },
          {
            label: "고객 사용자 비밀번호 수정",
            link: ROUTE.CUSTOMER_USER_UPDATE + "/password",
            resource: AUTH.ADMIN_USER,
            hidden: true,
          },
          {
            label: "사용자 역할",
            icon: <>∙</>,
            link: ROUTE.ADMIN_ROLE_LIST,
            resource: "owner",
          },
          {
            label: "사용자 권한",
            icon: <>∙</>,
            link: ROUTE.ADMIN_AUTHORITY_LIST,
            resource: "owner",
          },
        ],
      },
    ],
  },
  {
    title: "설계/견적 서비스 (운영자)",
    admin: true,
    route: [
      {
        label: "상담 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "중개 목록",
            icon: <>∙</>,
            link: ROUTE.BROKERAGE_ADMIN_LIST,
          },
          {
            label: "상담 등록 (고객용)",
            icon: <>∙</>,
            link: process.env.NEXT_PUBLIC_INBOUND_URL + "/widthLength?entry=counsel",
            passHref: true,
            target: "_blank",
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "상담 목록",
            icon: <>∙</>,
            link: ROUTE.COUNSEL_ADMIN_LIST,
          },
          {
            label: "견적상담 모델 관리",
            icon: <>∙</>,
            link: ROUTE.COUNSEL_MODEL_LIST,
            resource: AUTH.COUNSEL,
          },
          {
            label: "견적상담 요소 관리",
            icon: <>∙</>,
            link: ROUTE.COUNSEL_FACTOR_LIST,
            resource: AUTH.COUNSEL,
          },
          // {
          //   label: "상담 휴지통",
          //   icon: <>∙</>,
          //   link: ROUTE.COUNSEL_LIST_DELETED,
          // },
        ],
      },
      {
        label: "설계 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "기본 설계 설정",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_PREFERENCE,
          },
          {
            label: "기본 템플릿 등록",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_CREATE,
          },
          {
            label: "기본 템플릿 수정",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_UPDATE,
          },
          {
            label: "기본 템플릿 휴지통",
            icon: <>∙</>,
            link: ROUTE.DEFAULT_DESIGN_TEMPLATE_LIST_DELETE,
          },
        ],
      },
      {
        label: "품목 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "기본 분류 등록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CATEGORY_CREATE_ADMIN,
          },
          {
            label: "기본 분류 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CATEGORY_LIST_ADMIN,
          },
          {
            label: "기본 상품 등록",
            icon: <>∙</>,
            link: ROUTE.ITEM_CREATE_ADMIN,
          },
          {
            label: "기본 상품 목록",
            icon: <>∙</>,
            link: ROUTE.ITEM_LIST_ADMIN,
          },
        ],
      },
      {
        label: "(구)상담 관리",
        link: "",
        icon: null,
        children: [
          {
            label: "상담 등록",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_CREATE,
          },
          {
            label: "상담 목록",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_LIST,
          },
          {
            label: "상담 휴지통",
            icon: <>∙</>,
            link: ROUTE.CONTRACT_LIST_DELETED,
          },
        ],
      },
    ],
  },
  {
    title: "자재 서비스 (운영자)",
    admin: true,
    route: [
      {
        label: "간편견적 관리",
        link: "",
        icon: null,
        resource: AUTH.HCOST,
        children: [
          {
            label: "간편견적 인바운드 DB",
            icon: <>∙</>,
            link: ROUTE.HCOST_LIST,
            resource: AUTH.HCOST,
          },
          {
            label: "간편견적 등록 (고객용)",
            icon: <>∙</>,
            link: process.env.NEXT_PUBLIC_INBOUND_URL + "/type",
            passHref: true,
            target: "_blank",
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "간편견적 등록 (관리자)",
            icon: <>∙</>,
            link: ROUTE.HCOST_RESULT_CREATE,
            resource: AUTH.HCOST,
            action: "create",
          },
          {
            label: "간편견적 모델 관리",
            icon: <>∙</>,
            link: ROUTE.HCOST_MODEL_LIST,
            resource: AUTH.HCOST_MODEL,
          },
          {
            label: "간편견적 모델 수정",
            link: ROUTE.HCOST_MODEL_UPDATE,
            resource: AUTH.HCOST_MODEL,
            hidden: true,
          },
          {
            label: "간편견적 요소 관리",
            icon: <>∙</>,
            link: ROUTE.HCOST_FACTOR_LIST,
            resource: AUTH.HCOST_FACTOR,
          },
        ],
      },
      {
        label: "수익성 관리",
        link: "",
        icon: null,
        resource: AUTH.PROFIT,
        children: [
          {
            label: "수익성 인바운드 DB",
            icon: <>∙</>,
            link: ROUTE.PROFIT_LIST,
            resource: AUTH.PROFIT,
          },
          {
            label: "수익성 등록 (고객용)",
            icon: <>∙</>,
            link: ROUTE.PROFIT,
            passHref: true,
            target: "_blank",
            resource: AUTH.PROFIT,
            action: "create",
          },
          {
            label: "수익성 등록 (관리자)",
            icon: <>∙</>,
            link: ROUTE.PROFIT_CREATE,
            resource: AUTH.PROFIT,
            action: "create",
          },
          {
            label: "수익성 모델 관리",
            icon: <>∙</>,
            link: ROUTE.PROFIT_MODEL_LIST,
            resource: AUTH.PROFIT_MODEL,
          },
          {
            label: "수익성 모델 수정",
            link: ROUTE.PROFIT_MODEL_UPDATE,
            resource: AUTH.PROFIT_MODEL,
            hidden: true,
          },
          {
            label: "수익성 요소 관리",
            icon: <>∙</>,
            link: ROUTE.PROFIT_FACTOR_LIST,
            resource: AUTH.PROFIT_FACTOR,
          },
        ],
      },
      // {
      //   label: "병해충 관리",
      //   link: "",
      //   icon: null,
      //   resource: AUTH.DISEASE,
      //   children: [
      //     {
      //       label: "월별 주의 병해충 관리",
      //       icon: <>∙</>,
      //       link: ROUTE.DISEASE_LIST,
      //       resource: AUTH.DISEASE,
      //     },
      //   ],
      // },
      {
        label: "병해충 결과/로그 관리",
        link: "",
        icon: null,
        resource: AUTH.CROP,
        children: [
          {
            label: "병해충 진단 결과",
            icon: <>∙</>,
            link: ROUTE.CROP_DIAGNOSIS_RESULT,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "사용자 피드백",
            icon: <>∙</>,
            link: ROUTE.CROP_SATISFACTION,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "온라인 구매 연결",
            icon: <>∙</>,
            link: ROUTE.CROP_PRODUCT_CONNECTIONS,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "농약사 전화 연결",
            icon: <>∙</>,
            link: ROUTE.CROP_PESTICIDE_COMPANY_CONNECTIONS,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "제품 선택",
            icon: <>∙</>,
            link: ROUTE.CROP_PRODUCT_SELECTIONS,
            resource: AUTH.CROP,
            action: "manage",
          },
        ],
      },
      {
        label: "병해충 컨텐츠 관리",
        link: "",
        icon: null,
        resource: AUTH.CROP,
        children: [
          {
            label: "병해충 목록",
            icon: <>∙</>,
            link: ROUTE.CROP_DISEASE_LIST,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "병해충 상세정보 (NCPMS)",
            icon: <>∙</>,
            link: ROUTE.CROP_DISEASE_INFO_NCPMS,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "제품(비료/영양제) 상세 정보",
            icon: <>∙</>,
            link: ROUTE.CROP_PRODUCT_INFO,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "농약 상세 정보",
            icon: <>∙</>,
            link: ROUTE.CROP_PESTICIDE_INFO,
            resource: AUTH.CROP,
            action: "manage",
          },
          {
            label: "월별 병해충 정보",
            icon: <>∙</>,
            link: ROUTE.CROP_MONTHLY_INFO,
            resource: AUTH.CROP,
            action: "manage",
          },
        ],
      },
    ],
  },
];
