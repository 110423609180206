import Link from "next/link";
import { TextBody, TextCaption } from "@greenlabs/formula-components";

import { UserConst } from "@constants/user-const";

type Footer = {
  sidebarUse: boolean;
  sidebarOpen: boolean;
};

const Footer = ({ sidebarUse, sidebarOpen }: Footer) => {
  return (
    <>
      <div
        className={` absolute bg-[#F7F8FA] bottom-0 flex ${
          sidebarUse && sidebarOpen ? "w-full sm:ml-60 sm:w-[calc(100vw_-_16rem)]" : "w-full"
        }`}
      >
        <div className="clearfix w-full my-6 max-w-[1440px] mx-4 lg:mx-[7%] xl:mx-auto">
          <div>
            <Link href={"https://greenlabs.notion.site/1c52daedd0cd47669d03f3a61c4e3a41"} passHref={true}>
              <a target="_blank">
                <TextBody size="sm" weight="regular" color="gray-80">
                  {UserConst.policyType.TERMS}
                </TextBody>
              </a>
            </Link>
            <TextBody className="mx-3" size="sm" weight="regular" color="outline">
              |
            </TextBody>

            <Link href={"https://greenlabs.notion.site/5ec2f98bbc4644c0868e2391b743939d"} passHref={true}>
              <a target="_blank">
                <TextBody size="sm" weight="regular" color="gray-80">
                  {UserConst.policyType.PRIVACY}
                </TextBody>
              </a>
            </Link>
            <TextBody className="mx-3" size="sm" weight="regular" color="outline">
              |
            </TextBody>
            <Link href={"https://greenlabs.notion.site/28c0c503106c4775a015ab0d0c5c4819"} passHref={true}>
              <a target="_blank">
                <TextBody size="sm" weight="regular" color="gray-80">
                  {UserConst.policyType.LEGAL}
                </TextBody>
              </a>
            </Link>
          </div>

          <hr className="mt-5 mb-8" />

          <TextBody size="sm" weight="bold" color="neutral-primary-contents">
            (주)브이하우스랩
          </TextBody>
          <ul className="my-4 list-none">
            <li>
              <TextBody color="gray-70" size="sm" weight="regular">
                대표이사: 김평화
              </TextBody>
              <TextBody className="mx-3" color="outline" size="sm" weight="regular">
                |
              </TextBody>
              <TextBody color="gray-70" size="sm" weight="regular">
                사업자등록번호 : 142-81-68832
              </TextBody>
            </li>
            <li className="mt-1.5">
              <TextBody color="gray-70" size="sm" weight="regular">
                (05836) 서울 송파구 정의로 8길 9 AJ비젼타워(별관) 4층
              </TextBody>
              {/* <TextBody className="mx-3" color="outline" size="sm" weight="regular">
                |
              </TextBody>
              <TextBody color="gray-70" size="sm" weight="regular">
                전화: 02-6953-4379
              </TextBody> */}
            </li>
          </ul>
          <TextCaption weight="regular" color="gray-50">
            © (주)브이하우스랩 All rights reserved.
          </TextCaption>
        </div>
      </div>
    </>
  );
};

export default Footer;
